@import "src/theme/colors.scss";

.form {
  background-color: $gray25;
  border-radius: 10px;
  margin: 25px auto 0;
  padding: 15px;
  width: 500px;
}

.submit {
  width: 100%;
}

.loading {
  height: 100%;
  width: 100%;
}

.container {
  align-items: center;
  gap: 45px;
  display: flex;
  flex-direction: column;

  & > div {
    align-items: center;
    display: flex;
    flex-direction: column;
  }
}

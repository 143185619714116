@import "src/theme/colors.scss";

.container {
  border-radius: 15px;
  padding: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.button {
  margin: 0 auto 25px;
}

.content {
  gap: 15px;
  flex-direction: column;
}

.icon {
  font-size: 20px;
  margin-right: 10px;
}

.categoryHeader {
  transition: all 0.3s ease-in-out;
  cursor: pointer;
  padding: 15px;
  background-color: $white;
  border-radius: 15px;
  border: 1px solid $gray50;

  &:hover {
    border-color: $gray25;
    box-shadow: rgba(50, 50, 93, 0.05) 0px 30px 60px -12px,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  }
}

.category {
  background-color: $gray25;
  align-items: flex-start;
  display: flex;
  padding: 15px !important;
  border-radius: 15px;
  width: 100%;
  gap: 15px;
}

.subCategory {
  background-color: $white;
  gap: 15px;
  cursor: pointer;
  border: 1px solid $gray50;
  display: flex;
  align-items: flex-start;
  padding: 15px;
  border-radius: 15px;
  transition: all 0.4s ease-in-out;

  &:hover {
    border-color: $gray25;
    box-shadow: rgba(50, 50, 93, 0.05) 0px 30px 60px -12px,
      rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  }
}

.offerings {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.offering {
  border-radius: 15px;
  border: 1px solid $gray50;
  align-items: center;
  display: flex;
  gap: 25px;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  padding: 7px;

  &:hover {
    background-color: $gray25;
  }
}

.header {
  align-items: center;
  display: flex;
  gap: 25px;
}

.subCategories {
  gap: 15px;
  display: flex;
  flex-direction: column;
  flex: 1;
}

@import "src/theme/colors.scss";

.container {
  justify-content: space-between;
  background-color: $white;
  border-radius: 5px;
  border: 1px solid #{$gray}50;
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  padding: 15px;
  width: 100%;
}

.actions {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  & > button {
    width: 100%;
  }
}

.button {
  margin-top: 15px;
}

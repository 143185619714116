@import "src/theme/colors.scss";

.container {
  border: 1px dashed $gray;
  border-radius: 10px;
  display: flex;
  padding: 15px;
  margin-bottom: 25px;
}

.borderless {
  border: 0;
}

.imageContainer {
  width: 50%;
}

.information {
  flex: 1;
}

.image {
  border-radius: 0 10px;
  object-fit: fill;
  height: 100%;
  width: 100%;
}

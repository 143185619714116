@import "src/theme/media.scss";

.container {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 15px !important;
  border-bottom-left-radius: 15px !important;
  overflow: visible !important;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 25px;
  width: min(600px, 95vw) !important;
  overflow: auto;

  @media #{$responsive} {
    padding: 15px;
  }
}

.root {
  background-color: transparent !important;
}

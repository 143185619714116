@import "src/theme/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.autocompleteWrapper {
  align-items: center;
  display: flex;
}

.autocomplete {
  border: 0 !important;
  border-radius: 5px;
  height: 40px;
  padding: 8px 17px;
  width: 100%;

  &:focus,
  &:focus-visible,
  &:active {
    outline: none !important;
  }
}

.icon {
  font-size: 20px;
}

.mapIcon {
  color: $primary;
  font-size: 15px;
  margin-right: 10px;
}

.detail {
  align-items: center;
  display: flex;
  background-color: $primary20;
  border-radius: 5px;
  margin-top: 15px;
  padding: 7px 18px;
}

.error {
  margin-top: 15px;
}

@import "src/theme/colors.scss";

.container {
  background-color: $primary20;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  transition: all 0.5s;

  & + div {
    margin-top: 15px;
  }
}

.clickable {
  background-color: $white;
  cursor: pointer;

  &:hover {
    box-shadow: $card-shadow;
  }
}

@import "src/theme/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  & > * {
    & + * {
      margin-top: 25px;
    }
  }
}

.form {
  background-color: $gray25;
  border-radius: 5px;
  padding: 15px;
}

.submit {
  width: 100%;
}

@import "src/theme/colors.scss";

.container {
  background-color: $white;
  border-radius: 10px;
  display: flex;
  height: 180px;
  overflow: hidden;
  transition: all 0.3s ease-in-out;
  width: 100%;

  &:hover {
    box-shadow: $card-shadow;
    cursor: pointer;
  }
}

.information {
  display: flex;
  flex-direction: column;
  padding: 25px;
  width: 50%;
}

.houseContainer {
  background-color: $secondary20;
  align-items: center;
  display: flex;
  justify-content: center;
  width: 50%;
}

.image {
  object-fit: fill;
  height: 100%;
  width: 100%;
}

.icon {
  fill: $white;
  height: 100%;
  width: auto;
  padding: 25px;
}

@import "src/theme/colors.scss";

.container {
  width: 100%;

  & > * {
    & + * {
      margin-top: 10px;
    }
  }
}

.select {
  background-color: $white;
  border-radius: 5px;
  width: 100%;
}

.dropdown {
  border-radius: 5px !important;
  box-shadow: $card-shadow !important;
  padding: 0px !important;
  z-index: 9999 !important;
}

.dropdownItem {
  background-color: $white;
  color: $black;
  transition: all 0.5s ease-in-out;

  &:hover {
    color: $primary;
    background-color: $gray25;
  }
}

@import "src/theme/colors.scss";

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.option {
  align-items: center;
  display: flex;
}

.radio {
  & * {
    color: $black;
  }
}

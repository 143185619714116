@import "src/theme/colors.scss";

.form {
  background-color: $gray25;
  padding: 15px;
  border-radius: 15px;
}

.submit {
  margin-top: 15px;
  width: 100%;
}

@import "src/theme/colors.scss";

.title {
  padding-bottom: 2.5px;
  margin-bottom: 2.5px;
  width: 100%;
}

.dashedTitle {
  border-bottom: 1px dashed $gray;
}

.container {
  background-color: $white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.base {
  padding: 10px 0;
}

.dashed {
  border: 2px dashed $gray50;
}

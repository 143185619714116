@import "src/theme/colors.scss";

.form {
  background-color: $gray25;
  padding: 15px;
  border-radius: 10px;
}

.submit {
  width: 100%;
}

.loadingWrapper {
  min-height: 400px;
}

.previewWrapper {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
  border-radius: 30px;
  overflow: auto;
}

.previewInfo {
  padding: 25px;
}

.previewImage {
  display: block;
  width: 100%;
  object-fit: cover;
  max-height: 450px;
}

.role {
  font-size: 20px !important;
}

.bio {
  font-size: 20px !important;
  margin-top: 45px !important;
}

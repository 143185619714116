@import "src/theme/colors.scss";

.container {
  width: 100%;
}

.tabs {
  margin: 15px 0;
  align-items: center;
  display: flex;
  border: 1px solid #{$primary}30;
  border-radius: 10px;
  overflow: hidden;

  & > button {
    flex: 1;
  }
}

.tab {
  background-color: transparent;
  text-transform: capitalize;
  color: $primary !important;
  border-radius: 0px !important;
  transition: all 0.5s ease-in-out;
  border-color: transparent !important;

  &:disabled {
    color: $gray75 !important;
    background-color: transparent !important;
  }

  &:hover {
    border-color: transparent !important;
    background-color: $gray25 !important;
  }
}

.selectedTab {
  background-color: #{$primary}30 !important;
  color: $primary !important;

  &:hover {
    background-color: #{$primary}30 !important;
  }
}

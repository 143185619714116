@import "src/theme/colors.scss";

.container {
  background-color: $primary20;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 0 15px 15px !important;
}

.textAreaContainer {
  border-radius: 5px !important;
  margin: 0 !important;
  overflow: hidden;
  width: 100%;
}

.textArea {
  border-radius: 5px !important;
  box-shadow: none !important;
  border: 0 !important;
}

.createNoteContainer {
  margin-top: 15px;
  width: 100%;
}

.buttonGreeting {
  width: 100%;
}

.createNote {
  width: 100%;
}

@import "src/theme/colors.scss";

.container {
  background-color: $gray25;
  border-radius: 10px;
  min-height: 300px;
  padding: 25px;
}

.submit {
  align-items: center;
  display: flex !important;
  justify-content: center;
  flex: 1 !important;
}

.delete {
  align-items: center;
  display: flex !important;
  background-color: $semantic-negative !important;
  border: 0 !important;
  justify-content: center !important;
  flex: 1 !important;
}

.actions {
  background-color: $white;
  border-radius: 5px;
  display: flex;

  & > * {
    margin: 5px;
  }
}

.newModelButton {
  margin-left: 15px;
}

@import "src/theme/media.scss";

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 0;
  margin-top: 25px;
  width: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  height: 0;
  padding-bottom: 100px;
}

.sources {
  align-items: flex-start;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  overflow: auto;
  width: 100%;
  gap: 15px;
  margin-top: 15px;

  @media #{$responsive} {
    flex-direction: column;
    flex-wrap: nowrap;
  }
}

.source {
  display: flex;
  height: fit-content;
  flex: 1 1 32%;
  max-width: 32%;
  height: 230px;

  @media #{$responsive} {
    flex: unset;
    height: fit-content;
    width: 100%;
    max-width: unset;
  }

  & > * {
    margin-top: 0;
  }
}

.update {
  margin: 0 auto;
  width: fit-content;

  @media #{$responsive} {
    width: 100%;
  }
}

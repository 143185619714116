@import "src/theme/colors.scss";

.previewWrapper {
  display: flex;
  flex-direction: column;
  gap: 15px;
  width: 100% !important;
  height: 100% !important;
}

.preview {
  max-width: 90vw;
  max-height: 80vh;
  width: unset !important;
}

.attachment {
  gap: 10px;
  justify-content: space-between;
  align-items: center;
  display: flex;
  background-color: $primary20;
  border-radius: 5px;
  cursor: pointer;
  border: 2px dashed $primary60;
  padding: 5px;
}

.attachments {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.extension {
  border-radius: 5px;
  padding: 5px;
  border: 1px solid $semantic-positive;
}

@import "src/theme/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 25px;
  width: 100%;
}

.noProjects {
  justify-content: center;
  height: 100%;
  align-items: center;
  display: flex;
  width: 100%;
}

.icon {
  color: $primary;
  font-size: 35px;
  margin-right: 5px;
}

.noProjectsLabel {
  font-size: 25px;
}

@import "src/theme/colors.scss";

.form {
  flex: 1;
  padding: 15px;
  background-image: linear-gradient(to top right, $white, $gray50);
  border-radius: 10px;
}

.submit {
  width: 100%;
}

.innerForm {
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 15px;
}

.section {
  background-image: linear-gradient(to bottom right, $white, $gray50);
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
}

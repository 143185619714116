@import "src/theme/media.scss";

.container {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: auto;
  padding: 25px;
  width: 70%;

  @media #{$responsive} {
    width: 100%;
  }
}

.flow {
  align-items: center;
  flex: 1;
  justify-content: center;
}

.title {
  margin: 25px 0;
}

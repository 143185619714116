@import "src/theme/colors.scss";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 5px;
  background-color: $gray50;
  padding: 10px;

  & + .container {
    margin-top: 15px;
  }
}

.defaultContainer {
  border: 1px solid $primary60;
}

.content {
  align-items: center;
  gap: 15px;
  display: flex;
}

.contentWrapper {
  align-items: center;
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.defaultLabel {
  border-top: 1px dashed $primary60;
  width: 100% !important;
  padding: 10px 0 5px;
}

@import "src/theme/colors.scss";

.container {
  background-color: $primary;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: hidden;
}

.wrapper {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 9999;
  overflow: visible;
}

.fixedSidebar {
  z-index: unset;
  position: relative;
}

.expand {
  position: relative;
  cursor: pointer;
  background-color: $primary80;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  overflow: hidden;
}

.hidden {
  display: none !important;
}

.option {
  align-items: center;
  background-color: $primary;
  border: 0;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  padding: 10px 20px;
  transition: all 0.5s ease-in-out;
  overflow: hidden;
}

.selectedOption {
  background-color: $primary80;
}

.icon {
  fill: $white;
  font-size: 20px;
  min-width: 20px;
}

.labelContainer {
  align-items: flex-start;
  display: flex;
}

.label {
  margin-left: 15px;
  text-align: left;
}

.header {
  align-items: center;
  background-color: $primary60;
  display: flex;
  height: 70px;
  justify-content: center;
  padding: 15px;
}

.actions {
  margin-top: 100px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.actionButton {
  align-items: center;
  display: flex;
  background-color: transparent;
  border: 0;
  border-radius: 100% !important;
  cursor: pointer;
  height: 45px;
  justify-content: center;
  margin: 0 auto 0;
  transition: all 0.3s ease-in-out;
  width: 45px;

  &:hover {
    background-color: $primary60 !important;
  }
}

.chevron {
  color: $white;
  font-size: 25px;
}

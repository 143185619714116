@import "src/theme/colors.scss";

.header {
  align-items: center;
  background-color: $primary;
  display: flex;
  justify-content: space-between;
  padding: 25px;
  width: 100%;
}

.container {
  overflow: hidden !important;
}

.divContainer {
  overflow: hidden;
  display: flex;
  flex-direction: column;
}

.content {
  flex: 1;
  height: 0;
  display: flex;
  flex-direction: column;
  padding: 25px !important;
  overflow: hidden;
}

.tabs {
  margin-bottom: 25px;
}

.tab {
  color: $primary !important;
  text-transform: capitalize !important;
  font-size: 16px !important;
}

.indicator {
  background-color: $primary !important;
}

.tabContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
  overflow: auto;
}

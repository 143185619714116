@import "src/theme/media.scss";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
  justify-content: center;
}

.content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 25px;
}

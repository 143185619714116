@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.container {
  background-color: $gray25;
  bottom: 0;
  align-items: center;
  display: flex;
  left: 0;
  justify-content: space-between;
  padding: 15px;
  position: absolute;
  width: 100%;

  @media #{$responsive} {
    flex-direction: column;
  }
}

.status {
  align-items: center;
  display: flex;
}

.statusLabel {
  margin-right: 5px;
}

@import "src/theme/colors.scss";

.backdrop {
  overflow: hidden;
  box-sizing: border-box;
  height: 100%;
  left: 0;
  position: absolute;
  transition: all 0.3s ease-in-out;
  top: 0;
  width: 100%;
  overflow: hidden;
}

.blur {
  backdrop-filter: blur(5px);
}

%panel {
  display: flex;
  flex-direction: column;
  background-color: $white;
  box-sizing: border-box;
  box-shadow: rgba(100, 100, 111, 0.7) 0px 7px 29px 0px;
  overflow: hidden;
  position: absolute;
  z-index: 9999999;
}

.right {
  @extend %panel;

  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  height: calc(100% - 50px);
  top: 25px;
}

.bottom {
  @extend %panel;

  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  left: 25px;
  width: calc(100% - 50px);
}

.children {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}

@import "src/theme/colors.scss";

.container {
  padding: 25px;
  height: 100%;
}

.title {
  margin-bottom: 25px;
}

@import "src/theme/colors.scss";

.container {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  flex: 1;
  height: 0;
  font-size: 25px;
}

.content {
  align-items: center;
  display: flex;
  justify-content: center;
}

.icon {
  color: $primary;
  margin-right: 5px;
}

@import "src/theme/colors.scss";

.container {
  background-color: $white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.content {
  align-items: center;
  display: flex;
  width: 100%;
}

.uploadIcon {
  fill: $primary;
  font-size: 25px;
}

.errorBar {
  margin: 0 15px 15px;
}

.downloadButton {
  background-color: $primary20 !important;
  margin-right: 15px !important;
  padding: 7px !important;

  &:disabled {
    background-color: $gray25 !important;
  }
}

.downloadIcon {
  font-size: 25px;
  fill: $black;
}

.preview {
  border-radius: 5px;
  height: auto;
  width: 400px;
}

.modalContent {
  align-items: center;
  display: flex;
  justify-content: center;
}

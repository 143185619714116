@import "src/theme/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 25px;
}

.title {
  margin-bottom: 25px;
}

.loading {
  min-height: 300px;
}

.documentationTitle {
  margin-top: 15px;
}

.documentation {
  border: 2px dashed $gray;
  border-radius: 10px;
  margin-top: 15px;
  padding: 15px;
}

@import "src/theme/colors.scss";

.simpleTopbar {
  align-items: center;
  display: flex;
  height: 100px;
  justify-content: center;
  padding: 25px;
  position: relative;
  width: 100%;
}

.logo {
  height: 50px;
}

.clickable {
  cursor: pointer;
}

.actions {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  right: 0;
  position: absolute;
  top: 0;
}

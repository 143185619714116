@import "src/theme/colors.scss";

.content {
  background-color: $gray25;
  display: flex;
  flex-direction: column;
  height: 60vh;
  overflow: auto;
}

.submit {
  width: 100%;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
  width: 350px;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  width: 100%;
}

.submit {
  width: 100%;
}

.title {
  margin-bottom: 30px !important;
}

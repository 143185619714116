@import "src/theme/colors.scss";

.container {
  width: 100%;

  & > * {
    & + * {
      margin-top: 10px;
    }
  }
}

.count {
  margin-top: 15px !important;
}

.input {
  background-color: $white !important;
  border: 0 !important;
  border-radius: 5px !important;
  resize: none !important;
  box-shadow: none !important;
  width: 100% !important;

  & * {
    background-color: $white !important;
  }
}

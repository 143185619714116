@import "src/theme/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  position: relative;

  & > div {
    & + div {
      margin-top: 15px;
    }
  }
}

.summary {
  margin-top: 15px;
}

.invoiceInformation {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
  width: 100%;
}

.actionsContainer {
  display: inline-flex;
  flex-wrap: wrap;
  margin-top: 7.5px;
  gap: 15px;

  & > * {
    flex: 1;
  }
}

.tax {
  align-items: center;
  display: flex;
}

.invoiceRow {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.total {
  border-top: 1px dashed $gray;
  padding-top: 7.5px;
  margin-top: 7.5px;
}

.subsectionTitle {
  border-bottom: 1px dashed $secondary40;
  padding-bottom: 8px;
  margin-bottom: 8px !important;
}

.lineItems {
  border-bottom: 1px solid $secondary40;
  margin-bottom: 8px;
  padding-bottom: 8px;
}

.header {
  align-items: center;
  display: flex;
}

.headerSection {
  flex: 1;

  & + .headerSection {
    margin-left: 15px;
  }
}

.billDetail {
  margin-top: 15px;
}

.totalSection {
  margin-top: 7.5px;
}

.invoiceItem {
  align-items: center;
  background-color: $gray25;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  padding: 5px 15px;

  & + .invoiceItem {
    margin-top: 5px;
  }
}

.modules {
  flex-direction: column;
  width: 100%;
  display: flex;
  gap: 25px;

  & > div {
    width: 100%;
  }
}

.container {
  overflow: auto;
}

.collapsable {
  border-radius: 15px;
  padding: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

@import "src/theme/colors.scss";

.terms {
  background-color: $gray25;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  padding: 25px;
  width: 100%;
}

.agreement {
  border-radius: 10px;
  flex: 1 1 auto;
  height: 0;
  overflow: auto;
  padding: 15px;
}

.form {
  height: 100%;
}

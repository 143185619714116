@import "src/theme/colors.scss";

.dropdown {
  border-radius: 5px;
  box-shadow: $card-shadow;
  background-color: $white;
  padding: 15px;
  max-height: 300px;
  width: 300px;
  overflow: auto;
}

.apply {
  width: 100%;
}

.actions {
  display: flex;
  margin-top: 15px;

  & > button {
    flex: 1;

    & + button {
      margin-left: 10px;
    }
  }
}

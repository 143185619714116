@import "src/theme/colors.scss";

.picker {
  border: 0 !important;
  border-radius: 5px !important;
  width: 100%;
}

.dropdown {
  border: 0 !important;
  border-radius: 5px !important;
  box-shadow: $card-shadow;
  z-index: 9999 !important;
}

.container {
  width: 100%;

  & > * {
    & + * {
      margin-top: 10px;
    }
  }
}

.container {
  width: 100%;

  & > * {
    & + * {
      margin-top: 10px;
    }
  }
}

.group {
  display: flex !important;
  flex-direction: column !important;
}

.container {
  display: flex;
  flex-direction: column;

  & > * {
    & + * {
      margin-top: 20px;
    }
  }
}

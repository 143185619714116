@import "src/theme/colors.scss";

.form {
  transition: all 0.3s ease-in-out;
  padding: 5px;
  border-radius: 10px;
  position: relative;
  overflow: visible;
  flex: 1;
}

.valid {
  border: 2px solid #{$semantic-positive}35;
}

.invalid {
  border: 2px solid #{$semantic-negative}35;
}

.infoIcon {
  font-size: 20px;
  fill: $semantic-negative;
  cursor: pointer;
}

.checkIcon {
  font-size: 20px;
  fill: $semantic-positive;
  cursor: pointer;
}

.infoContainer {
  width: fit-content;
  height: 20px;
}

.field {
  align-items: center;
  display: flex;
  width: 100%;
  gap: 15px;
}

.menuIcon {
  display: flex;
  align-items: center;
  justify-items: center;
  fill: $gray !important;
  cursor: pointer;
  border: 2px solid $gray75 !important;
  padding: 5px;
  border-radius: 10px;
  height: 100%;
}

.menuItem {
  align-items: center;
  gap: 5px;
  display: flex;
  font-size: 15px !important;
  color: $gray;

  & * {
    fill: $gray !important;
  }
}

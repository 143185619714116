@import "src/theme/colors.scss";

.container {
  overflow: auto;
}

.section {
  border-radius: 5px;
  background-color: $gray25;
  padding: 15px;

  & + * {
    margin-top: 15px;
  }
}

.subSection {
  border-radius: 5px;
  background-color: $gray50;
  padding: 15px;
}

%field {
  & + div {
    border-top: 1px dashed $gray;
    margin-top: 10px;
    padding-top: 10px;
  }
}

.verticalField {
  @extend %field;

  & > * {
    & + * {
      margin-top: 5px;
    }
  }
}

.field {
  @extend %field;

  align-items: center;
  display: flex;

  & > * {
    & + * {
      margin-left: 5px;
    }
  }
}

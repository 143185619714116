@import "src/theme/colors.scss";

.container {
  background-color: $white;
  height: 100%;
  width: 100%;
}

.flow {
  height: 100%;
  margin: 0 auto;
  width: 600px;
}

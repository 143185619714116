@import "src/theme/colors.scss";

.form {
  background-color: $gray25;
  border-radius: 10px;
  min-height: 300px;
  padding: 15px;
}

.submit {
  margin-top: 15px;
}

@import "src/theme/colors.scss";

%item {
  align-items: center;
  display: flex;
  justify-content: space-between;
  border-radius: 5px;
  padding: 5px 15px;
  margin-right: 25px;
  flex: 1;
}

.item {
  @extend %item;
}

.optionalItem {
  @extend %item;

  background-color: #{$semantic-inactive}20;
}

.container {
  display: flex;
  flex-direction: column;

  border-radius: 10px;
  border: 1px solid #{$gray}70;
  padding: 15px;
}

.lines {
  display: flex;
  flex-direction: column;
}

.itemWrapper {
  position: relative;
  display: flex;
  align-items: center;
}

.infoIcon {
  cursor: pointer;
  display: flex;
  position: absolute;
  right: 7px;
  top: 50%;
  transform: translate(50%, -50%);
}

.divider {
  margin: 5px 0;
  border-bottom: 1px dashed $gray75;
}

.container {
  display: flex;
  width: 100%;
}

.content {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
}

.information {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

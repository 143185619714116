@import "src/theme/colors.scss";

.container {
  align-items: center;
  display: flex;
  background-color: $primary80;
  border-radius: 5px;
  padding: 15px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;

  & + .container {
    margin-top: 15px;
  }

  &:hover {
    background-color: $primary60;
    box-shadow: $card-shadow;
  }
}

.information {
  flex: 1;

  & > * {
    & + * {
      border-top: 1px dashed $white;
      margin-top: 5px;
      padding-top: 5px;
    }
  }
}

.chevron {
  color: $white;
  font-size: 15px;
  margin-left: 15px;
}

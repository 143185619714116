@import "src/theme/colors.scss";

.container {
  align-items: center;
  display: flex;
  margin-bottom: 15px;
  width: 100%;
}

.items {
  align-items: center;
  display: flex;
  height: fit-content;
  justify-content: center;
  width: 100%;
}

.progressBar {
  height: 2px;
  mix-blend-mode: screen;
}

.progressItem {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 100%;
  width: 100%;
}

.itemBackground {
  background-color: $primary;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.text {
  background-color: black;
  padding-bottom: 10px;
  mix-blend-mode: lighten;
}

.itemWrapper {
  position: relative;
  margin: 0 15px;
  overflow: hidden;
}

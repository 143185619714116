@import "src/theme/colors.scss";

.submit {
  width: 100%;
}

.title {
  margin-bottom: 15px;
}

.form {
  border-radius: 5px;
  background-color: $gray25;
  padding: 15px;
  width: 100%;
}

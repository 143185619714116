@import "src/theme/colors.scss";

.room {
  align-items: center;
  flex-direction: column;
  display: flex;
  background-color: $primary;
  border-radius: 5px;
  height: 90%;
  justify-content: space-between;
  padding: 15px;
  transition: all 0.3s ease-in-out;
  width: 95%;
  overflow: hidden;
}

.roomContent {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
  overflow: hidden;
  width: 100%;
}

.roomWrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 50%;
}

.addAppliance {
  border: 0 !important;
  margin: 5px;
}

.appliances {
  border-top: 1px dashed $white;
  margin-top: 15px;
  padding: 15px 0;
  flex: 1 !important;
  height: 0;
}

.roomHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.roomName {
  margin-right: 10px;
}

.roomActions {
  align-content: center;
  border-top: 1px dashed $white;
  display: flex;
  padding-top: 15px;
  width: 100%;
  flex-flow: wrap;

  & > * {
    flex: 1;
  }
}

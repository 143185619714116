.icon {
  font-size: 20px;
}

.container {
  align-items: center;
  display: flex;
}

.actions {
  margin-left: 5px;

  display: flex;

  & > button {
    & + button {
      margin-left: 5px;
    }
  }
}

.label {
  flex: 1;
  width: 0;
}

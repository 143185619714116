@import "src/theme/colors.scss";

.form {
  flex: 1;
  padding: 15px;
  background-image: linear-gradient(to top right, $white, $gray50);
  border-radius: 10px;
}

.submit {
  width: 100%;
}

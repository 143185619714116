.preview {
  border-radius: 5px;
  height: auto;
  width: 400px;
}

.previewModal {
  align-items: center;
  display: flex;
  justify-content: center;
}

.panel {
  padding: 25px;
}

.plusIcon {
  width: 15px;
}

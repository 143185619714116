@import "src/theme/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.titleContainer {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.title {
  margin-right: 15px;
}

.content {
  display: flex;
  flex-direction: column;
  padding: 15px;
}

.attachment {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
}

.attachments {
  align-items: center;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
}

.attachmentWrapper {
  cursor: pointer;
  border-radius: 5px;
  background-color: $primary20;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 120px;
  width: 120px;
}

.previewWrapper {
  border-radius: 10px;
  background-color: $primary20;
  align-items: center;
  display: flex;
  justify-content: center;
  height: 70vh;
}

.preview {
  border-radius: 5px;
  height: auto;
  width: 400px;
}

.modalPreview {
  align-items: center;
  display: flex;
  justify-content: center;
}

.actions {
  margin-top: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;

  & button {
    width: 100%;
  }
}

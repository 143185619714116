.container {
  background-image: url("../../../../assets/background-ellipse.svg");
  background-repeat: no-repeat;
  background-size: contain;
  background-color: white;
  height: calc(100vh - 60px);
  width: 100%;
}

.flow {
  padding: 24px;
  margin: 0 auto;
  width: 60%;
  z-index: 1;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  width: 100%;
}

.headerInfo {
  & + * {
    margin-top: 25px;
  }
}

.titles {
  max-width: 500px;

  & + .titles {
    margin-top: 25px;
  }
}

.header {
  min-height: 60px;
  position: relative;
  width: 100%;
}

%button {
  position: absolute;
  top: 50%;
  transform: translate(0, -50%);
}

.previousButton {
  @extend %button;

  left: 0;
}

.nextButton {
  @extend %button;

  right: 0;
}

.icon {
  font-size: 20px;
}

.content {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: flex-start;
  overflow-y: auto;
  width: 100%;
}

@import "src/theme/colors.scss";

.form {
  padding: 15px;
  background-color: $gray25;
}

.submit {
  width: 100%;
}

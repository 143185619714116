@import "src/theme/colors.scss";

.title {
  margin-bottom: 25px;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
}

.header {
  background-color: $gray25;
  display: flex;
  border-radius: 10px;
  flex-direction: row-reverse;
  justify-content: space-between;
  margin-bottom: 25px;
  padding: 15px;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
}

.table {
  background-color: $gray25;
  border-radius: 10px;
  flex: 1;
  height: 0;
  overflow: auto;
  padding: 15px;
}

.tableComponent {
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
}

.column {
  background-color: $white !important;
}

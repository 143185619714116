@import "src/theme/colors.scss";

.container {
  flex: 1;
  height: 0;
  overflow: auto;
}

.form {
  border-radius: 5px;
  background-color: $gray25;
  padding: 15px;
}

.formActions {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;

  & > button {
    width: 100%;

    & + button {
      margin-top: 15px;
    }
  }
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  width: 350px;
}

.submit {
  width: 100%;
}

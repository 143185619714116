@import "src/theme/colors.scss";

.container {
  align-items: center;
  display: flex;
  height: 100%;
  width: 100%;

  & > button {
    flex: 1;
    margin-right: 15px;
  }
}

.menuIcon {
  color: $primary;
  border-radius: 5px;
  background-color: $white;
  padding: 5px;
  height: 42px;
  width: 42px;
}

.menu {
  border-radius: 5px !important;
  box-shadow: $card-shadow !important;
  padding: 0 !important;
}

.item {
  padding: 10px 15px !important;
}

.prompt {
  font-size: 20px;
  margin-bottom: 15px;
}

.content {
  align-items: center !important;
}

.container {
  display: flex;
  height: 100%;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

@import "src/theme/colors.scss";

.loading {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  width: 100%;
}

.tableWrapper {
  background-color: $gray25;
  border-radius: 10px;
  flex: 1;
  height: 0;
  padding: 15px;
  overflow: auto;
}

.table {
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  z-index: inherit !important;
}

.csvLink {
  padding: 10px 15px;

  & > * {
    & + * {
      margin-left: 15px;
    }
  }
}

@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.container {
  align-items: center;
  background-color: $background;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 25px;
  width: 100%;
}

.flow {
  flex: 1;
  justify-content: flex-start;
  width: 75%;

  @media #{$responsive} {
    width: 100%;
  }
}

.actions {
  display: flex;
  position: absolute;
  top: 0;
  right: 0;
}

.title {
  margin-bottom: 15px;
}

.header {
  display: flex;
  position: relative;
}

.closeButton {
  margin-left: 10px;
}

@import "src/theme/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
}

.title {
  margin-bottom: 25px;
}

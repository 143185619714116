.container {
  align-items: center;
  display: flex;
  border-radius: 5px;
  padding: 5px 15px;
  width: fit-content;
}

.icon {
  height: 17px;
}

@import "src/theme/colors.scss";

.information {
  border-bottom: 1px dashed $gray;
  border-top: 1px dashed $gray;
  display: flex;
  margin: 15px 0;
  padding: 15px 0;
  width: 100%;

  & > * {
    flex: 1;
  }
}

.icon {
  width: 15px;
}

.archiveButton {
  background-color: $semantic-negative !important;
  border: 0 !important;
}

.actions {
  align-items: center;
  display: flex;

  & > * {
    flex: 1;
    & + * {
      margin-left: 15px;
    }
  }
}

.preview {
  border-radius: 5px;
  height: auto;
  width: 400px;
}

.modalPreview {
  align-items: center;
  display: flex;
  justify-content: center;
}

.appointment {
  padding-right: 45px;
  justify-content: space-between;
  align-items: center;
  display: flex;

  & + .appointment {
    margin-top: 15px;
  }
}

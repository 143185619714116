@import "src/theme/colors.scss";

.container {
  border-radius: 15px;
  padding: 25px;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px,
    rgba(0, 0, 0, 0.3) 0px 18px 36px -18px;
}

.header {
  align-items: center;
  display: flex;
  gap: 25px;
}

.tableWrapper {
  display: flex;
  flex-direction: column;
  height: 1000px;
  width: 100%;
}

.onClickDescription {
  gap: 5px;
  align-items: center;
  display: flex;
}

.actions {
  align-items: center;
  gap: 10px;
  display: flex;
}

@import "src/theme/colors.scss";

.container {
  background-color: $gray25;
  border-radius: 5px;
  min-height: 500px;
  padding: 15px;
  width: 100%;
}

.nextStepButton {
  margin-top: 15px;
  width: 100%;
}

@import "src/theme/colors.scss";

.container {
  padding: 5px 15px;
  width: fit-content;
  border-radius: 10px;
  width: fit-content;

  * {
    text-align: center;
  }
}

.inactive {
  background-color: #{$semantic-negative}20;
}

.active {
  background-color: #{$semantic-positive}20;
}

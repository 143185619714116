@import "src/theme/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  width: 100%;

  & + .container {
    border-top: 1px dashed $gray;
    margin-top: 15px;
    padding-top: 15px;
  }
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

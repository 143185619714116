.children {
  margin-top: 15px;
}

.inlineFields {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;

  & > * {
    flex: 1;
    width: 0;
    margin-top: unset !important;
  }
}

@import "src/theme/media.scss";

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
  width: 100%;
}

.sourcesContainer {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 0;
  overflow: auto;
  margin: 15px 0;
  padding: 10px 15px;
  width: 400px;

  @media #{$responsive} {
    padding: 0;
    width: 100%;
  }
}

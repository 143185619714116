@import "src/theme/colors.scss";

.container {
  align-items: center;
  display: flex;
}

.checkIcon {
  color: $semantic-positive;
  height: 30px;
  width: 30px;
  margin-left: 5px;
}

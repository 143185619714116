@import "src/theme/colors.scss";

.container {
  box-shadow: $card-shadow;
  border-radius: 10px;
  display: flex;
  height: 100%;
  overflow: hidden;
  width: 100%;
}

.details {
  height: 100%;
  width: 500px;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  & > * {
    & + * {
      margin-top: 25px;
    }
  }
}

.columns {
  display: flex;
}

.column {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;

  & > * {
    & + * {
      margin-top: 15px;
    }
  }
}

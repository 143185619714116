@import "src/theme/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  gap: 15px;

  & > * {
    background-color: $secondary;
    border-radius: 5px;
    padding: 10px;
  }
}

.doubleSection {
  display: flex;
  & > * {
    width: 50%;
  }
}

.terms {
  background-color: white;
  border-radius: 5px;
  box-shadow: 0 4px 16px 0 rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  margin: 40px auto 0;
  max-width: 600px;
  padding: 32px;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
}

.submit {
  width: 100%;
}

.info {
  margin-bottom: 25px;
}

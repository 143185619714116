@import "src/theme/media.scss";

.title {
  margin-bottom: 15px;
}

.container {
  width: 400px;

  @media #{$responsive} {
    width: 100%;
  }
}

.submit {
  margin-top: 15px;
  width: 100%;
}

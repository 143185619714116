@import "src/theme/colors.scss";

.fieldWrapper {
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  & + .fieldWrapper {
    margin-top: 15px;
  }
}

.field {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  width: 100%;
}

.iconContainer {
  align-items: center;
  display: flex;
  justify-content: center;
}

.infoIcon {
  cursor: pointer;
  color: $primary;
  font-size: 20px;
  margin-left: 15px;
}

.title {
  margin-bottom: 5px;
}

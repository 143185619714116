.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.form {
  display: flex;
  flex-direction: column;
  margin-top: 25px;
  width: 350px;
}

.link {
  margin-top: 15px;
}

.submitActions {
  display: flex;

  & > button {
    flex: 1;

    & + button {
      margin-left: 15px;
    }
  }
}

@import "src/theme/colors.scss";

.container {
  flex: 1;
  height: 0;
  display: flex;
  flex-direction: column;
}

.heading {
  align-items: center;
  display: flex;
  gap: 15px;
}

.form {
  flex: 1;
  overflow: auto;
}

.submit {
  width: 100%;
}

.section {
  display: flex;
  flex-direction: column;
  gap: 15px;
  padding: 15px;
  border-radius: 10px;
  background-image: linear-gradient(to bottom right, $white, $gray50);
}

.tabs {
  padding: 15px 0;
  align-items: center;
  display: flex;

  & > button {
    flex: 1;
  }
}

.tab {
  background-color: transparent;
  text-transform: capitalize;
  color: $primary !important;
  border-radius: 0px !important;
  transition: all 0.5s ease-in-out;
  border-color: transparent !important;

  &:first-child {
    border-top-left-radius: 10px !important;
    border-bottom-left-radius: 10px !important;
  }

  &:last-child {
    border-top-right-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
  }

  &:disabled {
    color: $gray75 !important;
    background-color: transparent !important;
  }

  &:hover {
    border-color: transparent !important;
    background-color: $gray25 !important;
  }
}

.selectedTab {
  background-color: #{$primary}30 !important;
  color: $primary !important;

  &:hover {
    background-color: #{$primary}30 !important;
  }
}

.prompt {
  padding-top: 100px !important;
  flex: 1;
  padding: 15px;
  border-radius: 10px;
  background-image: linear-gradient(to top right, $white, $gray50);
}

.innerForm {
  gap: 15px;
  display: flex;
  flex-direction: column;
  height: 100%;
}

@import "src/theme/colors.scss";

.floor {
  background-color: $gray50;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  padding: 15px;
  position: absolute;
}

.floorContent {
  display: flex;
  flex-wrap: wrap;
  background-color: $gray25;
  border-radius: 5px;
  flex: 1;
  display: flex;
  overflow: auto;
}

.floorHeader {
  align-items: center;
  background-color: $gray25;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  padding: 15px;
  position: relative;
  width: 100%;
}

.floorNumber {
  align-items: center;
  display: flex;
  border-radius: 50%;
  background-color: $primary;
  height: 30px;
  justify-content: center;
  position: absolute;
  left: 15px;
  width: 30px;
}

.addButton {
  align-items: center !important;
  display: flex !important;
  justify-content: center !important;
  position: absolute !important;
  right: 15px !important;
}

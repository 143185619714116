@import "src/theme/colors.scss";

.container {
  background-color: $white;
  border-radius: 5px;
  padding: 15px;

  & + div {
    margin-top: 15px;
  }
}

.highlighted {
  box-shadow: $card-shadow;
  border: 2px solid $primary60;
}

.formContent {
  align-items: flex-start;
  display: flex;
  justify-content: space-between;
}

.actions {
  align-items: center;
  display: flex;
}

.actionsContainer {
  align-items: center;
  display: flex;
  height: 30px;
}

.icon {
  color: $primary;
  cursor: pointer;
  font-size: 20px;

  & + .icon {
    margin-left: 5px;
  }
}

.description {
  width: 50%;
}

.descriptionContainer {
  width: 50%;
}

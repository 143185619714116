@import "src/theme/colors.scss";

.container {
  align-items: center;
  color: $white;
  display: flex;
  background-color: $primary60;
  border-radius: 10px;
  justify-content: center;
  padding: 5px;
  width: auto;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.sourcesContainer {
  display: flex;
  flex-direction: column;
  width: 350px;
}

.skipButton {
  margin-top: 15px;
}

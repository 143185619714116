@import "src/theme/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 15px;
}

.floors {
  display: flex;
  flex: 1;
  height: 100%;
  position: relative;
}

.pagination {
  align-items: center;
  display: flex;
  margin: 0 auto;
}

.paginationButton {
  border: 0 !important;

  & + .paginationButton {
    margin-left: 15px;
  }
}

.addRoomButton {
  margin: auto;
}

@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.wrapper {
  background-color: $secondary;
  border-radius: 10px;
  display: flex;
  flex: 1;
  padding: 0 15px 15px;
  width: 100%;
}

.flow {
  flex: 1;
  width: 100%;
}

.success {
  border-radius: 10px;
  align-items: center;
  background-color: rgba($semantic-positive, 0.2);
  display: flex;
  justify-content: center;
  margin-top: 15px;
  padding: 15px;
  width: 100%;
}

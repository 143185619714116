@import "src/theme/colors.scss";

.sourceForm {
  margin-bottom: 15px;
}

.title {
  margin-bottom: 15px;
}

.error {
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  background-color: #{$semantic-negative}20;
  width: 100% !important;
}

.methodActions {
  align-items: center;
  flex-direction: column;
  display: flex;
  gap: 10px;
  width: 100%;
  background-color: $gray50;
  border-radius: 5px;
  padding: 10px;

  & > button {
    width: 100% !important;
  }
}

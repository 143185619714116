@import "src/theme/colors.scss";

.form {
  display: flex;
  flex-direction: column;
  background-color: $gray50;
  border-radius: 10px;
  padding: 15px;

  & button {
    width: 100%;
  }
}

.prompt {
  margin-bottom: 15px !important;
}

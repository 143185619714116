@import "src/theme/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 15px;
  height: 0;
}

.pane {
  overflow: auto;
  flex: 1;
  height: 0;
}

@import "src/theme/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
  margin: auto;
  width: 100%;
}

.title {
  margin-right: 25px;
}

.header {
  align-items: flex-start;
  display: flex;
  margin-bottom: 25px;
}

.listWrapper {
  background-color: $gray25;
  border-radius: 10px;
  display: flex;
  flex: 1;
  height: 0;
  padding: 15px;
}

.input {
  background-color: $gray25 !important;
  border: 0 !important;
  border-radius: 10px !important;
  margin: 0 0 25px 25px;
  max-width: 25% !important;
  width: fit-content !important;

  & * {
    background-color: $gray25 !important;
  }
}

.noInformation {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  width: 100%;
}

@import "src/theme/colors.scss";

.container {
  align-items: center;
  background-color: rgba($semantic-negative, 0.2);
  border-radius: 5px;
  display: flex;
  padding: 5px 15px;
}

.icon {
  fill: $semantic-negative;
  margin-right: 15px;
}

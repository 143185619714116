.modal {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.appointment {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding-right: 45px;
}

@import "src/theme/colors.scss";

.container {
  overflow-x: auto;
  overflow-y: visible;
  height: fit-content;

  & tr {
    height: 70px;
    max-height: 70px;
  }
}

%fixedContainer {
  z-index: 1;

  & .cell {
    color: $primary;
  }
}

.leftContainer {
  @extend %fixedContainer;

  box-shadow: rgba(0, 0, 0, 0.15) 5px 0px 20px 0px;
  width: fit-content !important;
  min-width: fit-content !important;
}

.rightContainer {
  @extend %fixedContainer;

  box-shadow: rgba(0, 0, 0, 0.15) 0px 0px 20px 5px;
  width: fit-content !important;
  min-width: fit-content !important;
}

.fixedRow {
  background-color: $primary20 !important;
}

.row {
  transition: all 0.3s ease-in-out;
  background-color: $white;

  &:hover {
    border-bottom-color: $primary;
  }

  & > td {
    & + td {
      border-left: 1px solid $gray50;
    }
  }
}

.cell {
  gap: 15px;
  align-items: center;
  display: flex;
  justify-content: space-between;
  font-size: 14px !important;
  padding: 5px 7.5px;
  height: 100%;
  overflow: hidden;
}

.label {
  height: fit-content;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  min-width: 0;
}

.fixedHeader {
  & th {
    background-color: $primary80 !important;
  }
}

.clickableRow {
  cursor: pointer !important;
}

.interactableCell {
  background-color: $gray25;
}

.header {
  text-align: left;

  & th {
    background-color: $primary60;

    & > .cell {
      color: $white;
    }

    & + th {
      border-left: 1px solid $white;
    }
  }
}

.leftHeader {
  border-right: 1px solid $primary60;
}

@import "src/theme/colors.scss";
@import "src/theme/colors.scss";

.badge {
  display: inline;
  background-color: $semantic-positive;
  border-radius: 10px;
  font-size: 10px;
  top: 0;
  right: 0;
  transform: translate(50%, -50%);
  font-size: 10px;
  padding: 0 5px;
  height: fit-content;
  position: absolute;
}

.container {
  position: relative;
  align-items: center;
  display: flex;
}

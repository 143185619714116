@import "src/theme/colors.scss";

.container {
  align-items: center;
  display: flex;
  width: 100%;
}

.divider {
  height: 0;
  border-bottom: 2px solid $primary;
  flex: 1;
  margin: 0 15px;
}

@import "src/theme/colors.scss";

.container {
  display: flex;
  flex-direction: column;
  padding: 15px;
  height: 100%;
}

.header {
  display: flex;
  padding: 15px 15px 0px 15px;
}

.noReceipt {
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  background-color: #{$semantic-negative}20;
}

.processing {
  border-radius: 5px;
  padding: 5px;
  background-color: #{$semantic-positive}20;
}

.detailHeading {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.invoiceItems {
  display: flex;
  flex-direction: column;
  background-color: $gray50;
  padding: 15px;
  border-radius: 10px;
}

.invoiceItem {
  align-items: center;
  display: flex;
  justify-content: space-between;

  & + .invoiceItem {
    margin-top: 10px;
  }

  &:last-child {
    padding-top: 10px;
    border-top: 1px dashed $primary;
  }
}

.section {
  background-color: $gray25;
  border: 1px dashed $gray;
  border-radius: 10px;
  padding: 15px;

  display: flex;
  flex-direction: column;
  gap: 15px;

  & + .section {
    margin-top: 15px;
  }
}

.actions {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  background-color: $gray25;
  border-radius: 10px;
  padding: 15px;
}

@import "src/theme/colors.scss";

.container {
  border-radius: 5px;
  overflow: hidden;
  min-width: fit-content;
  margin-left: 5px;
}

.button {
  color: $gray50 !important;
  border-radius: 0 !important;
  padding: 5px !important;
}

.selected {
  color: $primary !important;
}

@import "src/theme/colors.scss";

.appliance {
  background-color: $primary60;
  border-radius: 5px;
  cursor: pointer;
  flex: 1;
  text-align: center;
  margin: 5px;
  height: fit-content;
  transition: all 0.2s ease-in-out;

  &:hover {
    background-color: $white;
    color: $primary !important;
  }
}

.applianceLabel {
  transition: all 0.2s ease-in-out;
  height: 100%;
  padding: 5px;
  width: 100%;

  &:hover {
    color: $primary !important;
  }
}

@import "src/theme/media.scss";

.container {
  align-items: center;
  display: flex;
  flex: 1;
  justify-content: center;
  width: 100%;
  overflow: auto !important;

  @media #{$responsive} {
    flex-direction: column;
    justify-content: flex-start;
  }
}

.information {
  @media #{$responsive} {
    align-items: center;
    display: flex;
    flex-direction: column;
    padding: 15px;
    width: 100%;
  }
}

%panel {
  display: flex;
  flex-direction: column;

  width: 50%;

  @media #{$responsive} {
    max-height: 50%;
    width: 100%;
  }
}

.rightPanel {
  @extend %panel;

  align-items: center;
  justify-content: center;
  margin-left: 25px;

  // @media #{$responsive} {
  //   height: 50%;
  //   width: 100%;
  // }
}

.leftPanel {
  @extend %panel;
}

.getStartedButton {
  margin-top: 25px;
}

.topLabel {
  margin-bottom: 5px;
}

.banner {
  object-fit: contain;
  width: 100%;

  @media #{$responsive} {
    max-height: 100%;
    max-width: 95%;
    width: fit-content;
  }
}

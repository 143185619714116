@import "src/theme/colors.scss";

.container {
  align-items: flex-start;
  display: flex;
  background-color: $primary20;
  border-radius: 5px;
  padding: 15px;
  margin-top: 15px;
}

.content {
  flex: 1;
  width: 0;
}

.noteIcon {
  color: $primary;
  font-size: 25px;
  margin-right: 5px;
}

.header {
  align-items: center;
  justify-content: space-between;
  display: flex;
  border-bottom: 1px solid $primary60;
  padding-bottom: 7.5px;
  margin-bottom: 7.5px;
}

.icon {
  font-size: 15px;
}

.actions {
  align-items: center;
  display: flex;

  & > button {
    & + button {
      margin-left: 5px;
    }
  }
}

.title {
  align-items: center;
  display: flex;
}

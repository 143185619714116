@import "src/theme/colors.scss";

.container {
  padding: 5px 15px;
  width: fit-content;
  border-radius: 5px;
}

.emergency {
  background-color: #{$semantic-negative}20;
}

.normal {
  background-color: #{$semantic-inactive}20;
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
  padding: 25px;
}

.content {
  display: flex;
  flex-direction: column;
}

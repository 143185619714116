@import "src/theme/colors.scss";

.container {
  background-color: $primary20;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: space-between;
  overflow: hidden;
}

.notes {
  flex: 1;
  height: 0;
  padding: 15px;
  margin: 0 0 15px;
  overflow: auto;
}

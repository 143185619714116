@import "src/theme/colors.scss";

.selectAll {
  justify-content: space-between;
  display: flex;
  padding: 15px 15px 15px 40px;
  background-color: $gray25;
  border-radius: 10px;
  margin-bottom: 25px;
}

.selectAllCheckbox {
  background-color: $primary;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  margin-left: -15px;
  width: fit-content;

  & > * {
    color: $white !important;
  }
}

.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  height: 0;
}

.searchBar {
  width: 400px;

  & > input {
    border: 1px solid $gray50 !important;
    height: 100%;
  }
}

.header {
  justify-content: space-between;
  background-color: $gray25;
  border-radius: 10px;
  display: flex;
  flex-direction: row-reverse;
  padding: 15px;
  margin-bottom: 25px;
  gap: 15px;
}

.tableWrapper {
  flex: 1;
  height: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: 10px;
}

.table {
  border-radius: 10px;
  display: flex;
  flex: 1;
  height: 0;
  overflow-y: overlay;
}

.pagination {
  background-color: $gray25;
  border-radius: 10px;
  margin-top: 25px;
  margin-left: auto;
  padding: 15px;
}

.tableActions {
  display: flex;
  flex-direction: row-reverse;
  flex: 1;

  & > * {
    margin-left: 15px;
  }
}

.icon {
  font-size: 20px;
  margin-right: 10px;
}

.checkboxWrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  & > * {
    width: fit-content;
  }

  & * {
    padding: 0 !important;
  }
}

.header {
  background-color: transparent;
  align-items: center;
  display: flex;
  justify-content: space-between;
  flex: 1;
  min-width: 350px;
  width: 350px;
}

.actions {
  align-items: center;
  display: flex;
  gap: 5px;
}

.title {
  gap: 15px;
  display: flex;
  align-items: center;
}

.iconButton {
  padding: 10px;
}

.icon {
  font-size: 20px;
}

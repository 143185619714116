@import "src/theme/colors.scss";

.content {
  display: flex;
  flex-direction: column;
}

.wrapper {
  background-color: $primary20;
  border-radius: 10px;
  padding: 15px;
  height: 100%;
  overflow: auto;
}

.collapsedIcon {
  color: $secondary80;
  font-size: 20px;
}

.jobHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.job {
  & + div {
    margin-top: 10px;
  }
}

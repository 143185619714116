@import "src/theme/colors.scss";

.container {
  background-color: $gray25;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
}

.actions {
  gap: 15px;
  align-items: center;
  display: flex;
}

.editPanel {
  display: flex;
  flex-direction: column;
  height: 70vh;
  overflow: hidden;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 15px 15px 0;
}

.content {
  background-color: $gray25;
  display: flex;
  height: 0;
  flex: 1;
  padding: 15px;
}

.actions {
  background-color: $white;
  border-radius: 10px;
  height: 100%;
  margin-right: 20px;
  overflow: auto;
}

.projectSpecifics {
  display: flex;
  flex-direction: column;
  background-color: $white;
  border-radius: 10px;
  flex: 1;
  width: 0;
}

.allProjectsButton {
  align-items: center !important;
  background-color: transparent !important;
  box-shadow: none !important;
  display: flex !important;
  border: 0 !important;
}

.chevron {
  font-size: 15px;
  margin-right: 5px;
}

.panel {
  flex: 1;
  height: 100%;
  overflow: auto;
  width: 450px;
}

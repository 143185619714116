@import "~antd/dist/antd.less";
@import url("https://fonts.googleapis.com/css?family=Noto Serif");

// antd overrides

.ant-descriptions-item-label {
  text-transform: uppercase;
  font-size: 10px !important;
  color: #5f5f5f !important;
}

.ant-drawer-content-wrapper {
  box-shadow: none !important;
}

.ant-menu.ant-menu-dark .ant-menu-sub {
  background: #232c37;
}

.ant-select-show-search.ant-select-single:not(.ant-select-customize-input)
  .ant-select-selector {
  border-radius: 6px;
  border: 1px solid rgba(108, 114, 128, 0.2);
}

.ant-popover-buttons {
  text-align: center;
}

.ant-popover-buttons button {
  margin-left: 12px;
}

.ant-upload-list.ant-upload-list-picture-card {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.ant-upload.ant-upload-drag {
  margin: 1rem 0;
  border: 2px dashed #c9c9c9;
}

.ant-upload-list-picture-card .ant-upload-list-item {
  background-color: #ecf0f8;
  border-radius: 5px;
  border: 0;
  padding: 0;
  overflow: hidden;
}

.ant-upload-list-picture-card-container {
  margin: 5px 0 5px 5px;
}

.ant-upload.ant-upload-select.ant-upload-select-picture-card {
  width: 45px;
  height: 45px;
  border-radius: 5px;
  border: 0;
  background: #ecf0f8;
  margin: 15px 0 15px 15px;
}

.ant-pagination {
  margin-top: 20px;
  text-align: right;

  .ant-pagination-item-link[type="button"],
  .ant-pagination-item {
    border: 0;
    border-radius: 5px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  .ant-pagination-disabled .ant-pagination-item-link[type="button"] {
    background: #f0f0f0;
  }

  .ant-pagination-item-active {
    background-color: #e9e4de;
  }
}

.ant-picker-panel-container {
  border-radius: 5px;
  box-shadow: 0px 0px 15px rgba(#484848, 0.15);
  overflow: hidden;
}

// Google autocomplete styling

.autocomplete-dropdown-container {
  background-color: #fff;
  box-shadow: 0 1px 3px 0 #e6ebf1;
  padding: 10px !important;
  position: absolute;
  width: 100%;
  z-index: 100;
}

.autocomplete-dropdown-container:empty {
  display: none;
}

// Remove google chrome auto complete styles

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

@body-background: #F9F7F5;@primary-color: #354760;@link-color: #354760;@success-color: #4fc94c;@warning-color: #eba157;@error-color: #974B13;@btn-font-size-lg: 14px;@heading-color: #354760;@text-color: #1D1F23;@text-color-secondary: #5F5F5F;@border-color-base: #5F5F5F;@disabled-color: rgba(0, 0, 0, 0.5);@height-lg: 52px;@height-base: 40px;@height-sm: 27px;@btn-border-radius-base: 5px;@btn-font-weight: 700;@btn-font-size-sm: 10px;@box-shadow-base: 0 2px 4px #1D1F23;@input-border-color: #EDEAE4;@input-placeholder-color: #91918F;@control-padding-horizontal: 18px;@layout-body-background: #F9F7F5;@layout-header-background: #F9F7F5;@layout-header-height: 60px;@layout-header-padding: 0px;@form-item-margin-bottom: 22px;@descriptions-item-padding-bottom: 2px;@descriptions-title-margin-bottom: 10px;@table-header-bg: #F9F7F5;@table-row-hover-bg: #F9F7F5;
.container {
  margin: auto;
  width: 450px;
}

.submit {
  margin-top: 15px;
  width: 100%;
}

.title {
  margin-bottom: 25px;
}

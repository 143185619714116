@import "src/theme/colors.scss";

.container {
  background-color: $primary20;
  border-radius: 10px;
  height: 100%;
  padding: 15px;
  overflow: hidden;
}

.list {
  height: 100%;
}

@import "src/theme/colors.scss";

.container {
  background-color: $primary;
  border-radius: 0;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0;
  left: 25px;
  top: 0;
  z-index: 10;
  position: absolute;
  width: 350px;
}

.form {
  background-color: $gray50;
  padding: 10px;
  border-radius: 5px;
}

.containerDisabled {
  background-color: $gray25;
}

.header {
  align-items: center;
  display: flex;
  height: 70px;
  justify-content: center;
  width: 100%;
}

.icon {
  color: $white;
  margin-right: 15px !important;
  font-size: 20px !important;
}

.spinner {
  color: $gray !important;
}

.filters {
  padding: 25px;

  & > div {
    & + div {
      margin-top: 15px;
    }
  }
}

.applyButton {
  flex: 1;
}

.action {
  width: 40px;
}

.actions {
  align-items: center;
  display: flex;
  height: 45px;
  width: 100%;

  & > button {
    min-height: 100%;

    & + button {
      margin-left: 5px;
    }
  }
}

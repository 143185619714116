@import "src/theme/colors.scss";

.user {
  align-items: center;
  display: flex;
  border-radius: 5px;
  background-color: $gray50;
  padding: 10px;

  & + .user {
    margin-top: 5px;
  }
}

.field {
  align-items: center;
  display: flex;

  & > * {
    & + * {
      margin-left: 5px;
    }
  }

  & + div {
    border-top: 1px dashed $gray;
    margin-top: 10px;
    padding-top: 10px;
  }
}

.content {
  flex: 1;
  width: 0;
  margin-left: 10px;
}

.icon {
  fill: $primary;
  font-size: 30px;
}

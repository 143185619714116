@import "src/theme/colors.scss";

.container {
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  margin-left: 16px;
}

.button {
  border: 0 !important;
  display: flex !important;
  height: fit-content !important;
  padding: 5px !important;
}

%icon {
  color: $primary !important;
  font-size: 20px;
}

.icon {
  @extend %icon;
}

.loading {
  @extend %icon;

  padding: 5px !important;
}

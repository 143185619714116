@import "src/theme/colors.scss";

.wrapper {
  border-radius: 5px;
  background-color: $gray50;
  padding: 15px;
  margin: 15px 0;
}

.container {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.reason {
  margin-top: 10px !important;
  padding-top: 10px;
  border-top: 1px dashed $gray;
}

.switch {
  align-items: center;
  display: flex;
}

.buttons {
  align-items: center;
  display: flex;
  gap: 15px;
  margin-bottom: 15px;

  & > button {
    flex: 1;
  }
}

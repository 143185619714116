@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.container {
  align-items: center;
  display: flex;
  background-color: $primary80;
  flex: 1;
  justify-content: center;
  width: 100%;
}

.content {
  display: flex;
  flex-direction: column;
  width: 400px;

  @media #{$responsive} {
    max-width: 90%;
  }
}

.subtitle {
  margin: 25px 0;
}

.contactUs {
  text-decoration: underline;
  text-align: center;
  color: $white !important;
  margin-top: 50px;
  width: 100%;
}

@import "src/theme/colors.scss";

.form {
  background-color: $gray25;
  border-radius: 5px;
  padding: 15px;
}

.submit {
  width: 100%;
}

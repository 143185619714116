@import "src/theme/colors.scss";
@import "src/theme/media.scss";

.container {
  flex: 1;
  height: 0;
  overflow: auto;
  margin-top: 25px;

  @media #{$responsive} {
    height: fit-content;
  }
}

.content {
  display: flex;
  height: 100%;
  gap: 25px;
  width: 100%;

  @media #{$responsive} {
    height: fit-content;
    flex-direction: column;
  }
}

.notice {
  gap: 15px;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  height: 100%;
}

.collapsable {
  max-height: 100%;
  flex: 1;
  background-color: transparent;
  padding: 0;
  border-radius: 5px;
  overflow: auto;

  @media #{$responsive} {
    flex: unset;
    height: fit-content;
    max-height: unset;
    overflow: hidden;
  }
}

.header {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  background-color: $secondary;
  padding: 25px;
}

$background: #f9f7f5;

$primary: #354760;
$primary80: #415674;
$primary60: #4c6587;
$primary40: #81a6bf;
$primary20: #ecf0f8;

$black: #484848;
$text: #484848;
$gray: #626262;
$gray75: #7a7a7a;
$gray50: #dbdbdb;
$gray25: #f0f0f0;

$secondary: #f1ede8;
$secondary20: #e9e4de;
$secondary40: #ccc7c0;
$secondary60: hsl(38, 5%, 71%);
$secondary80: #91918f;
$secondary100: #53554d;

$semantic-negative: #b93c3c;
$semantic-critical: #dfbf19;
$semantic-positive: #0d9944;
$semantic-inactive: #0d5ba7;

$white: #fff;

$card-shadow: 0px 0px 15px rgba($black, 0.25);

:export {
  primaryColor: $primary;
  white: $white;
}

@import "src/theme/colors.scss";

.addIcon {
  font-size: 15px;
  margin-right: 5px;
}

.container {
  transition: all 0.3s ease-in-out;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 10px;
  border-radius: 10px;
  border: 2px solid $gray50;
}

.error {
  border: 2px solid $semantic-negative;
}

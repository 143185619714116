@import "src/theme/colors.scss";

.container {
  display: flex;
  width: 100%;
}

.content {
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
}

.information {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

.icon {
  color: $primary;
  font-size: 20px;
  margin: auto 0;
}

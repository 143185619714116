@import "src/theme/colors.scss";

.summary {
  background-color: $white;
  border: 2px dashed $gray50;
  border-radius: 5px;
  margin: 15px 0;
  padding: 15px;
  width: 100%;
}

.row {
  align-items: center;
  display: flex;
  justify-content: space-between;

  & + .row {
    padding-top: 5px;
    margin-top: 5px;
  }

  &:last-child {
    border-top: 1px dashed $gray;
  }
}

.invoiceTitle {
  border-bottom: 1px solid $gray;
  margin-bottom: 5px;
  padding-bottom: 5px;
}

@import "src/theme/colors.scss";

.container {
  padding: 25px;
}

.form {
  background-color: $gray25;
  border-radius: 5px;
  margin-top: 25px;
  padding: 15px;
}

.submit {
  width: 100%;
}

.container {
  width: 100%;
}

.field {
  border: 0;
  border-radius: 5px;
  padding: 8px 17px;
  width: 100%;

  &:focus,
  &:active {
    border: 0;
  }

  & + * {
    margin-top: 10px;
  }
}

@import "src/theme/colors.scss";

.submit {
  width: 100%;
}

.form {
  background-color: $gray25;
  padding: 15px;
}

.container {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.header {
  align-items: center;
  display: flex;
  margin-bottom: 15px;
  width: 100%;

  & > * {
    flex: 1;
    & + * {
      margin-left: 15px;
    }
  }
}

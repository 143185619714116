@import "src/theme/colors.scss";

.container {
  justify-content: space-between;
  align-items: center;
  background-color: $white;
  display: flex;
  flex-direction: row-reverse;
  height: 70px;
  padding: 15px 25px;
  position: relative;
  width: 100%;
}

.menuIcon {
  height: auto;
  width: 25px;
}

.userIcon {
  background: $white;
  font-size: 30px;
  fill: $primary;
}

.menu {
  border-radius: 5px !important;
  box-shadow: $card-shadow !important;
  width: 150px;
}

.menuItem {
  height: fit-content !important;
  margin: 0 !important;
  max-width: 100% !important;
  padding: 10px !important;
  text-align: center !important;
  width: 100% !important;

  & + .menuItem {
    border-top: 1px solid $gray25;
  }
}

@import "src/theme/colors.scss";

.item {
  border-radius: 0;
  background-color: $secondary20;

  & + .item {
    border-top: 2px dashed $primary;
  }
}

@import "src/theme/colors.scss";

.invoiceHeader {
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.collapsable {
  background-color: #f0eded;
  border-radius: 5px;
  margin-top: 10px;
}

.headerLabel {
  margin-right: 10px;
}

.collapsedIcon {
  color: $secondary80;
  font-size: 20px;
}
